<!-- (c) 才酝通 -->
<template>
    <div class="container" v-if="user">
        <div class="mask" v-if="openmask">
            <div class="popup borderbox" v-if="changeNick">
                <div class="cancel" @click="cancel()">
                    <img src="../../assets/img/close.png" />
                </div>
                <div class="input-box">
                    <input
                        type="text"
                        class="input-item"
                        v-model="form.content"
                        placeholder="请输入昵称"
                    />
                </div>
                <div class="confirm" @click="submitHandle()">确认</div>
            </div>
        </div>
        <nav-header :title="header_title"></nav-header>
        <div class="user-avatar">
            <div class="value">
                <img :src="user.avatar" />
                <input
                class="input-avatar"
                type="file"
                accept="image/*"
                @change="uploadAvatar"
                />
            </div>
            <div class="name">点击更换头像</div>
        </div>
        <div class="form-box">
            <div class="tit">账户信息</div>
            <div class="group-item" @click="changeNickname">
                <div class="name">昵称</div>
                <div class="value">
                <span>{{ user.nick_name }}</span>
                <img src="../../assets/img/new/back@2x.png" class="arrow" />
                </div>
            </div>
            <div class="group-item" @click="changePassword">
                <div class="name">修改密码</div>
                <div class="value">
                <span v-if="user.is_password_set === 1">已设置</span>
                <span class="un" v-else>设置密码</span>
                <img src="../../assets/img/new/back@2x.png" class="arrow" />
                </div>
            </div>
            <div class="group-item" @click="goLogout">
                <div class="name">退出登录</div>
                <div class="value">
                <img src="../../assets/img/new/back@2x.png" class="arrow" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Dialog } from 'vant-green';
Vue.use(Dialog);

import axios from "axios";
import NavHeader from "../../components/nav-header.vue";

import { mapState, mapMutations } from "vuex";

export default {
    components: {
        NavHeader
    },
    computed: {
        ...mapState(["isLogin", "config", "user"]),
    },
    data() {
        return {
            openmask: false,
            changeNick: false,
            loading: false,
            form: {
                nick_name: null,
                content: null,
            },
            logoutDialog: false,
            header_title: '个人信息'
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        ...mapMutations(["submitLogin", "logout"]),
        getData() {
            this.$api.User.Detail().then((res) => {
                this.submitLogin(res.data);
            });
        },
        uploadAvatar(e) {
            let files = e.target.files;
            if (!files[0].type.match(/.jpg|.png|.jpeg/i)) {
                this.$message.error("图片格式错误,请上传png/jpg/jpeg格式的图片");
                return;
            }
            if (files[0].size > 1024000) {
                this.$message.error("图片大小不超过1M");
                return;
            }
            let formData = new FormData();
            formData.append("file", files[0]);
            axios
            .post("/user/change_avatar", formData)
            .then((res) => {
                if (res.data.code === 0) {
                    this.$message.success("上传头像成功");
                     setTimeout(() => {
                        this.getData();
                    }, 500);
                } else {
                    this.$message.error(res.data.message);
                }
            })
            .catch((e) => {

            });
        },
        changeNickname() {
            if (this.user.is_set_nickname === 1) {
                this.$message.error("已经设置过昵称，无法再次修改");
                return;
            }
            this.changeNick = true;
            this.openmask = true;
        },
        cancel() {
            this.logoutDialog = false;
            this.changeNick = false;
            this.openmask = false;
        },
        changePassword() {
            this.$router.push({
                name: "ChangePassword",
            });
        },
        submitHandle() {
            console.log(!this.form.content)
            if (this.loading) {
                return;
            }
            if (!this.form.content) {
                this.$message.error("请输入昵称");
                return;
            }
            this.loading = true;
            this.$api.User.NicknameChange({ nickName: this.form.content }).then((res) => {
                this.loading = false;
                this.$message.success("修改成功");
                this.form.content = null;
                this.cancel();
                setTimeout(() => {
                    this.getData();
                }, 500);
            }).catch((e) => {
                this.loading = false;
                this.$message.error(e.message);
            });
        },
        goLogout() {
          let message = '确认退出登录？';
            Dialog.confirm({
                message: message,
                confirmButtonColor: '#3ca7fa',
                confirmButtonText: '确认',
                cancelButtonText: '取消',
            })
            .then(() => {
                // on confirm
                this.$api.Auth.Logout()
                .then((res) => {
                  this.$message.success("已安全退出");
                  this.cancel();
                  this.logout();
                  this.$router.push({
                      name: "User",
                  });
                })
                .catch((e) => {
                  this.loading = false;
                  this.$message.error("网络错误");
                });
            })
            .catch(() => {
                // on cancel
            });
        },
    }
}
</script>
<style lang="less" scoped>
.container {
  box-sizing: border-box;
  padding-top: 50px;
  background: #fff;
}
.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  .mobile-box {
    width: 325px;
    height: 305px;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: relative;
    .cancel {
      position: absolute;
      top: -42px;
      right: 0px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .mobile {
      width: 100%;
      height: 20px;
      display: flex;
      justify-content: center;
      margin-top: 15px;
    }
    .input-box {
      width: 100%;
      height: 45px;
      display: flex;
      justify-content: center;
      margin-top: 15px;
      .input-item {
        width: 284px;
        height: 45px;
        display: flex;
        align-items: center;
        border: none;
        border-bottom: 1px solid #d8d8d8;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        outline: none;
      }
      .input-short {
        width: 170px;
        height: 45px;
        display: flex;
        align-items: center;
        border: none;
        border-bottom: 1px solid #d8d8d8;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        outline: none;
      }
      .captcha {
        width: 110px;
        height: 45px;
        img {
          width: 110px;
          height: 45px;
        }
      }
      .buttons {
        width: 110px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
      }
    }

    .confirm {
      width: 255px;
      height: 44px;
      background: #1784ed;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      cursor: pointer;
      font-weight: 500;
      color: #ffffff;
      margin: 0 auto;
      margin-top: 50px;
    }
  }
  .popup {
    width: 325px;
    height: 204px;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: relative;
    .cancel {
      position: absolute;
      top: -42px;
      right: 0px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .text {
      width: 100%;
      height: 45px;
      display: flex;
      justify-content: center;
      margin-top: 35px;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      padding: 0 15px;
    }
    .input-box {
      width: 100%;
      height: 45px;
      display: flex;
      justify-content: center;
      margin-top: 35px;
      .input-item {
        width: 255px;
        height: 45px;
        display: flex;
        align-items: center;
        border: none;
        border-bottom: 1px solid #d8d8d8;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        outline: none;
      }
    }

    .confirm {
      width: 255px;
      height: 44px;
      background: #1784ed;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      cursor: pointer;
      font-weight: 500;
      color: #ffffff;
      margin: 0 auto;
      margin-top: 50px;
    }
  }
}
.user-avatar {
  width: 100%;
  height: auto;
  float: left;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 50px;
  .value {
    width: 64px;
    height: 64px;
    float: left;
    position: relative;
    img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
    }
    .input-avatar {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 64px;
      height: 64px;
      border-radius: 50%;
    }
  }

  .name {
    width: auto;
    height: 13px;
    font-size: 13px;
    font-weight: 400;
    color: #666666;
    line-height: 13px;
    margin-top: 15px;
  }
}
.form-box {
  width: 100%;
  height: auto;
  float: left;
  margin-bottom: 50px;
  background-color: white;
  .tit {
    width: 100%;
    height: auto;
    float: left;
    box-sizing: border-box;
    padding-left: 20px;
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    line-height: 16px;
    margin-bottom: 10px;
  }

  .group-item {
    width: 100%;
    height: 56px;
    float: left;
    display: flex;
    box-sizing: border-box;
    padding: 8px 20px;
    border-bottom: 1px solid #f3f6f9;

    .name {
      width: 150px;
      height: 40px;
      float: left;
      line-height: 40px;
      font-size: 16px;
      color: #333333;
    }

    .value {
      flex: 1;
      height: 40px;
      text-align: right;
      font-size: 14px;
      color: #666666;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .un {
        color: #c1c4cb;
      }
      .arrow {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-left: 15px;
      }
      .u-image,
      u-image {
        display: inline-block;
      }

      input {
        display: inline-block;
        width: 100%;
        height: 40px;
        line-height: 40px;
        border: 0;
        text-align: right;
        outline: none;
        font-size: 14px;

        &::placeholder {
          color: #c1c4cb;
        }
      }
    }

    .captcha {
      width: auto;
      height: auto;
      line-height: 40px;
      margin-left: 30px;

      .u-image {
        display: inline-block;
      }
    }

    .buttons {
      width: auto;
      height: auto;
      line-height: 40px;
      margin-left: 30px;

      .send-sms-button,
      .promo-code-check-button {
        width: 100px;
        height: 30px;
        line-height: 30px;
        margin-top: 3px;
        text-align: center;
        color: white;
        background-color: #3ca7fa;
        font-size: 14px;
        border-radius: 3px;
        float: left;
      }

      .promo-code-check-button {
        width: 50px;
      }
    }
  }
}
</style>
